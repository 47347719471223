import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright"></p>
        <p> Beautiful photography by {' '}
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.alejandramaria.com/"
            >Alejandra Maria</a>
        </p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
